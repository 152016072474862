import usePost from "#/hooks/util-hooks/usePost";
import { alertError, alertSuccess } from "#/store/actions/notification.actions";
import { CircularProgress } from "@material-ui/core";
import { ErrorMessage, Formik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

// Utility functions
const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
const removeNonNumeric = (num) => (num || "").toString().replace(/[^0-9]/g, "");
const removeLeadingZero = (num) => num.toString().replace(/^0+/, "");

// Symbol mapping based on type
const typeSymbols = {
  fixed: "₦",
  percentage: "%"
};

const formatAmount = (value) => {
  return parseFloat(removeNonNumeric(value || ""));
};

const formatPaymentTerm = (term) => {
  switch (term) {
    case "annual_payment":
      return "annual";
    case "bi_annual_payment":
      return "bi-annual";
    case "quaterly_payment":
      return "quarterly";
    case "monthly_payment":
      return "monthly";
    case "weekly_payment":
      return "weekly";
    case "day_payment":
      return "day";
    case "hourly_payment":
      return "hourly";
    case "minute_payment":
      return "minute";
    default:
      return "";
  }
};

const convertDate = (dateString) => {
  const date = new Date(dateString);

  // Extract year, month, and day
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  // Return the formatted date as "YYYY-MM-DD"
  return `${year}-${month}-${day}`;
};

const AddBillSet = ({ apartmentID, bill, refetch, setModal }) => {
  const dispatch = useDispatch();

  const [selectedTerms, setSelectedTerms] = useState({
    annual_payment: !!bill?.annual_payment?.active || bill?.payment_term === "annual",
    bi_annual_payment:
      !!bill?.bi_annual_payment?.active || bill?.payment_term === "bi-annual",
    quaterly_payment:
      !!bill?.quaterly_payment?.active || bill?.payment_term === "quaterly",
    monthly_payment: !!bill?.monthly_payment?.active || bill?.payment_term === "monthly",
    weekly_payment: !!bill?.weekly_payment?.active || bill?.payment_term === "weekly",
    day_payment: !!bill?.day_payment?.active || bill?.payment_term === "day",
    hourly_payment: !!bill?.hourly_payment?.active || bill?.payment_term === "hourly",
    minute_payment: !!bill?.minute_payment?.active || bill?.payment_term === "minute"
  });

  const [defaultTerm, setDefaultTerm] = useState("");

  const initialValues = bill
    ? {
        apartment_id: apartmentID,
        name: bill?.name || "",
        amount: bill?.amount || "",
        payment_term: bill?.payment_term || "",
        payment_date: convertDate(bill?.payment_date) || "",
        amount_settings: bill?.amount_settings || "",
        annual_payment: bill?.annual_payment?.amount || bill?.amount || "",
        bi_annual_payment: bill?.bi_annual_payment?.amount || bill?.amount || "",
        quaterly_payment: bill?.quaterly_payment?.amount || bill?.amount || "",
        monthly_payment: bill?.monthly_payment?.amount || bill?.amount || "",
        weekly_payment: bill?.weekly_payment?.amount || bill?.amount || "",
        day_payment: bill?.day_payment?.amount || bill?.amount || "",
        hourly_payment: bill?.hourly_payment?.amount || bill?.amount || "",
        minute_payment: bill?.minute_payment?.amount || bill?.amount || "",
        contribution_fee: bill?.contribution_fee || 0,
        security_deposit: bill?.security_deposit || 0
      }
    : {
        apartment_id: apartmentID,
        name: "",
        amount: "",
        payment_term: "",
        payment_date: "",
        amount_settings: "",
        annual_payment: "",
        bi_annual_payment: "",
        quaterly_payment: "",
        monthly_payment: "",
        weekly_payment: "",
        day_payment: "",
        hourly_payment: "",
        minute_payment: "",
        contribution_fee: 0,
        security_deposit: 0
      };

  const fixedAmountValidation = Yup.string().matches(
    /^\d{1,3}(,\d{3})*$/,
    "Amount must be in a valid format"
  );

  const percentageValidation = Yup.string().matches(
    /^([1-9][0-9]?|100)$/,
    "Percentage must be between 1 and 100"
  );

  const paymentValidation = (term, defaultTerm) =>
    Yup.string().when([], {
      is: () => defaultTerm === term,
      then: fixedAmountValidation,
      otherwise: Yup.string().when("amount_settings", {
        is: "fixed",
        then: fixedAmountValidation,
        otherwise: percentageValidation
      })
    });

  const validationSchema = (defaultTerm) => {
    return Yup.object().shape({
      name: Yup.string().required("Please provide the bill name"),
      payment_date: Yup.string().required("Please provide the payment date"),
      amount_settings: Yup.string().required("Please provide the bill amount settings"),

      // Conditionally apply validation based on defaultTerm
      annual_payment: paymentValidation("annual_payment", defaultTerm),
      monthly_payment: paymentValidation("monthly_payment", defaultTerm),
      bi_annual_payment: paymentValidation("bi_annual_payment", defaultTerm),
      quaterly_payment: paymentValidation("quaterly_payment", defaultTerm),
      weekly_payment: paymentValidation("weekly_payment", defaultTerm),
      day_payment: paymentValidation("day_payment", defaultTerm),
      hourly_payment: paymentValidation("hourly_payment", defaultTerm),
      minute_payment: paymentValidation("minute_payment", defaultTerm)
    });
  };

  const handleTermChange = (term, setFieldValue) => {
    setSelectedTerms((prevState) => {
      const updatedState = { ...prevState, [term]: !prevState[term] };

      if (!updatedState[term]) {
        setFieldValue(term, "");
      }

      return updatedState;
    });
  };

  const handleFieldChange = (e, term, setFieldValue) => {
    const value = addCommas(removeNonNumeric(removeLeadingZero(e.target.value)));
    setFieldValue(term, value);
  };

  const handleSetDefault = (term, setFieldValue, values) => {
    setFieldValue(term, removeNonNumeric(values[term]));
    setDefaultTerm(term);
  };

  const { loading, mutate } = usePost(
    bill ? `/bill/update_bill/${bill?._id}?type=bill` : "/bill/store?type=bill",
    bill ? "patch" : "post",
    {
      onSuccess: (data) => {
        setModal(false);
        dispatch(alertSuccess(data.message || data.msg));
        refetch();
      },
      onError: (error) => {
        setModal(false);
        dispatch(alertError(error.message || error.msg));
        refetch();
      }
    }
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema(defaultTerm)}
      onSubmit={(values) => {
        if (!defaultTerm) {
          dispatch(alertError("Please select a default payment term"));
          return;
        }

        const data = {
          ...values,
          amount: formatAmount(values[defaultTerm]),
          payment_term: formatPaymentTerm(defaultTerm),
          annual_payment: {
            active: selectedTerms.annual_payment,
            amount: formatAmount(values.annual_payment),
            date: values.payment_date
          },
          bi_annual_payment: {
            active: selectedTerms.bi_annual_payment,
            amount: formatAmount(values.bi_annual_payment),
            date: values.payment_date
          },
          quaterly_payment: {
            active: selectedTerms.quaterly_payment,
            amount: formatAmount(values.quaterly_payment),
            date: values.payment_date
          },
          monthly_payment: {
            active: selectedTerms.monthly_payment,
            amount: formatAmount(values.monthly_payment),
            date: values.payment_date
          },
          weekly_payment: {
            active: selectedTerms.weekly_payment,
            amount: formatAmount(values.weekly_payment),
            date: values.payment_date
          },
          day_payment: {
            active: selectedTerms.day_payment,
            amount: formatAmount(values.day_payment),
            date: values.payment_date
          },
          hourly_payment: {
            active: selectedTerms.hourly_payment,
            amount: formatAmount(values.hourly_payment),
            date: values.payment_date
          },
          minute_payment: {
            active: selectedTerms.minute_payment,
            amount: formatAmount(values.minute_payment),
            date: values.payment_date
          }
        };

        // console.log(data);
        mutate(data);
      }}>
      {({ values, handleSubmit, handleChange, setFieldValue }) => (
        <form
          onSubmit={handleSubmit}
          className="billModal tw-h-[90%] tw-overflow-auto tw-bg-slate-50 tw-py-10 tw-px-14 tw-rounded">
          <h1 className="tw-text-center tw-text-[1.5rem] tw-font-semibold tw-mb-12">
            {bill ? "Edit Bill" : "Add Bill"}
          </h1>
          <div>
            <h1 className="tw-text-[#05213770] tw-font-bold tw-mb-4">
              Please select your preferred payment terms
            </h1>
            <div className="term tw-flex tw-flex-wrap tw-gap-x-10 tw-gap-y-3 tw-items-center">
              {Object.keys(selectedTerms).map((term) => (
                <Input
                  key={term}
                  name={term}
                  value={selectedTerms[term]}
                  handleChange={() => handleTermChange(term, setFieldValue)}
                  variant="checkbox"
                  radioLabel={term.replace("_", " ").replace(/_/g, " ")}
                  checked={selectedTerms[term]}
                />
              ))}
            </div>
          </div>

          <div className="inputs tw-flex tw-justify-between tw-gap-7 tw-items-center tw-my-10">
            <Input
              name="name"
              title="Name"
              type="text"
              value={values.name}
              handleChange={handleChange}
              placeholder="Name"
            />
            <Input
              name="payment_date"
              title="Date"
              type="date"
              value={values.payment_date}
              handleChange={handleChange}
              placeholder="Date"
            />
          </div>
          <div className="tw-flex tw-justify-between tw-gap-5 tw-items-center tw-my-10">
            <div className="tw-flex tw-flex-1 tw-flex-col">
              <label
                htmlFor="type-select"
                className="tw-text-[#05213770] tw-text-[15px] tw-font-bold tw-mb-2">
                Payment confirguration
              </label>
              <select
                name="amount_settings"
                value={values.amount_settings}
                onChange={(e) => {
                  handleChange(e);
                  // Reset payment term amounts when type changes
                  Object.keys(selectedTerms).forEach((term) => {
                    setFieldValue(term, "");
                  });
                }}
                className="tw-border tw-py-3 tw-px-3 tw-w-full tw-rounded-md tw-font-medium tw-text-x tw-text-primary tw-placeholder:text-primary tw-placeholder:font-medium"
                id="type-select"
                style={{ border: "solid 1px #062239" }}>
                <option value="" disabled>
                  Select type
                </option>
                <option value="fixed">Fixed</option>
                <option value="percentage">Percentage</option>
              </select>
              <ErrorMessage
                name="amount_settings"
                component="div"
                className="tw-text-[13px] tw-font-semibold tw-mt-1 tw-text-red-500"
              />
            </div>
          </div>

          {Object.keys(selectedTerms).map(
            (term) =>
              selectedTerms[term] && (
                <div style={{ margin: "2.5rem 0", position: "relative" }}>
                  <fieldset
                    key={term}
                    style={{
                      border: "solid 1px #062239",
                      padding: "10px 15px",
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem"
                    }}>
                    <legend className="tw-flex tw-items-center tw-justify-between tw-px-3">
                      <h2 className="tw-text-[15px] tw-font-semibold">
                        {term.replace("_", " ").toUpperCase()}:
                      </h2>
                      <div className="default-term">
                        <Input
                          name={`default_${term}`}
                          value={defaultTerm}
                          variant="checkbox"
                          handleChange={() =>
                            handleSetDefault(term, setFieldValue, values)
                          }
                          checked={defaultTerm === term}
                          radioLabel="Set as Default"
                        />
                      </div>
                    </legend>

                    <Input
                      name={term}
                      title="Amount"
                      type="text"
                      value={values[term]?.amount || values[term]}
                      handleChange={(e) => handleFieldChange(e, term, setFieldValue)}
                      placeholder="Amount"
                      symbol={
                        defaultTerm === term
                          ? typeSymbols.fixed
                          : typeSymbols[values.amount_settings]
                      }
                    />
                  </fieldset>
                  {/* <div>
                    <Input
                      name={`default_${term}`}
                      value={defaultTerm}
                      variant="checkbox"
                      handleChange={() => handleSetDefault(term, setFieldValue, values)}
                      checked={defaultTerm === term}
                      radioLabel="Set as Default"
                    />
                  </div> */}
                </div>
              )
          )}

          <div className="tw-flex tw-justify-center tw-items-center mtop-5">
            <button
              type="submit"
              className="tw-bg-blue-500 tw-text-white tw-px-7 tw-py-2 tw-rounded">
              {loading ? (
                <CircularProgress size={20} />
              ) : bill ? (
                "Update Bill"
              ) : (
                "Add Bill"
              )}
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default AddBillSet;

// Updated Input Component to handle symbols
const Input = ({
  name,
  title,
  type,
  value,
  handleChange,
  placeholder,
  checked,
  radioLabel,
  variant,
  symbol, // New prop for symbol
  others
}) => {
  switch (variant) {
    case "checkbox":
      return (
        <label className="radio-input" htmlFor={`checkbox-${name}`}>
          <input
            type="checkbox"
            name={name}
            value={value}
            id={`checkbox-${name}`}
            onChange={handleChange}
            checked={checked}
            className="tw-mr-2 tw-cursor-pointer"
          />
          <span className="checkmark tw-cursor-pointer"></span>
          <p className="tw-inline-block tw-cursor-pointer">{radioLabel}</p>
        </label>
      );
    default:
      return (
        <div className="tw-flex tw-flex-1 tw-flex-col input-full">
          <label
            htmlFor={`text-input-${name}`}
            className="tw-text-[#05213770] tw-text-[15px] tw-font-bold tw-mb-2">
            {title}
          </label>
          <div className="tw-relative">
            {symbol && (
              <span className="tw-absolute tw-left-3 tw-top-1/2 tw-transform tw--translate-y-1/2 tw-text-gray-500">
                {symbol}
              </span>
            )}
            <input
              type={type}
              name={name}
              value={value}
              onChange={handleChange}
              className={`tw-border tw-py-3 tw-px-6 tw-w-full tw-rounded-md tw-font-medium tw-text-x tw-text-primary tw-placeholder:text-primary tw-placeholder:font-medium ${
                symbol ? "tw-pl-10" : ""
              }`}
              id={`text-input-${name}`}
              placeholder={placeholder}
              style={{ border: "solid 1px #062239" }}
              {...others}
            />
          </div>
          <ErrorMessage
            name={name}
            component="div"
            className="tw-text-[13px] tw-font-semibold tw-mt-1 tw-text-red-500"
          />
        </div>
      );
  }
};
