import { Button, CircularProgress, Grid, Hidden } from "@material-ui/core";
import React, { Fragment, useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Styles, TypographyText, BorderLinearProgress } from "../../styles";
import sad from "#/assets/images/sad.png";
import { capitalizeFirstLetter, toCurrency } from "#/components/utils/util";
import CustomButton from "#/components/ui-components/button.component";
import AddTenant from "./components/addTenant";

import TenantByLid from "./components/tenantByLid";
import { useNewStyles } from "./apartmentStyle";
import { linkTenantLid } from "#/store/actions/tenant.actions";
import { useDispatch } from "react-redux";
import { getPropertyById } from "#/store/actions/property.actions";
import { authServiceApi } from "#/apis/authServiceApi";
import { showNotifications } from "#/store/actions/notification.actions";
import { returnUserData } from "#/apis/propertyServiceApi";
import ApartmentTenant from "./components/ApartmentTenant/ApartmentTenant";
import useFetch from "#/hooks/util-hooks/useFetch";

const ApartmentInfo = ({
  property,
  index,
  setStatus,
  id,
  loadingCharges,
  propertyId,
  handleAdvertiseApartment,
  advertising,
  checkingApartmentId
}) => {
  //----------------------------------------------------------------------------------------
  //Other Helpers
  //----------------------------------------------------------------------------------------
  const apartment = property.apartment[index];
  const { charges, tenant: apartmentTenants } = apartment;
  const endDate = new Date(apartment.rent_payment_date);
  const oneDay = 24 * 60 * 60 * 1000;

  const { data: chargesData, loading: chargeIsLoading } = useFetch(
    `/bill/get_apartment_bills/${id}`
  );

  const apartmentCharges = chargesData?.data?.filter((el) => el.name !== "Monthly Rent");

  //----------------------------------------------------------------------------------------
  //Helper hooks
  //----------------------------------------------------------------------------------------
  const classes = Styles();
  const newClasses = useNewStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [newLid, setNewLid] = useState(undefined);
  const [validated, setValidated] = useState(null);

  useEffect(() => {
    const user = returnUserData();
    if (!newLid || newLid.length < 8 || user.liveizy_id === newLid) return;
    setValidated("loading");
    authServiceApi()
      .get(`/user/validate/liveizy/${newLid}`)
      .then((data) => {
        setValidated(data.data.data);
      })
      .catch((error) => {
        dispatch(
          showNotifications(
            error?.response?.data?.message || "Invalid Liveizy ID",
            "error"
          )
        );
        setValidated(false);
      });
  }, [dispatch, newLid]);

  const dispatchTenantIdLinkUp = () => {
    const payload = { user_id: validated.liveizy_id };
    setLoading(true);

    dispatch(
      linkTenantLid(apartmentTenants[0]._id, payload, (success) => {
        if (success) setStatus("summary");
        dispatch(getPropertyById(property._id, "charges", "tenant"));
        setLoading(false);
      })
    );
  };

  //----------------------------------------------------------------------------------------
  //Helper functions
  //----------------------------------------------------------------------------------------
  // End DATE
  const getEnd = () => {
    const theEnd = new Date(apartment.rent_payment_date);
    return theEnd;
  };

  // GET DUE DATE
  const getStart = () => {
    let dDay;
    switch (apartment.payment_term) {
      case "monthly":
        dDay = 1;
        break;
      case "quarterly":
        dDay = 3;
        break;
      case "bi-annual":
        dDay = 6;
        break;
      case "annual":
        dDay = 12;
        break;
      default:
        dDay = null;
        break;
    }
    const startDate = endDate.setMonth(endDate.getMonth() - dDay);
    return new Date(startDate);
  };

  // GET REMAINING DAYS FOR RENT
  const remDays = () => {
    const firstDate = new Date();
    const secondDate = getEnd();
    const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
    return diffDays;
  };

  // val for Linear bar shwoing days remaining for rent
  const val = () => {
    const rentDuration = getEnd() - getStart();
    const days = rentDuration / oneDay / 2;
    const remDaysPerc = remDays() / days;
    const val = Math.round(remDaysPerc * 100);
    return val;
  };

  // Get Monthly Rent
  const getMonthlyRent = () => {
    const rent = apartment.price / 12;
    return rent;
  };

  //Fire when user wants to edit apartment
  const handleStartEditingApartment = useCallback(() => {
    history.push(`/apartment-edit/${propertyId}/${id}`);
  }, [history, id, propertyId]);

  const handleAddBills = useCallback(() => {
    history.push(`/create/bill-settings/${id}/${propertyId}`);
  }, [history, id, propertyId]);

  //render the apartment charges
  const checkCharges = () => {
    if (chargeIsLoading) return <p>Fetching Charges...</p>;
    if (apartmentCharges?.length >= 1) {
      return (
        <Grid item md={12} style={{ display: "flex", flexWrap: "wrap" }}>
          {apartmentCharges?.map((charge, i) => {
            return (
              <span key={i} variant="outlined" className={`${classes.amenities}`}>
                <span>
                  <Grid>
                    <Grid item md className="charge-card">
                      <TypographyText
                        variant="h6"
                        className="charge-title"
                        style={{ whiteSpace: "nowrap" }}>
                        {charge.name}
                      </TypographyText>

                      <TypographyText variant="subtitle1">
                        {toCurrency(charge.amount)}
                      </TypographyText>
                    </Grid>
                    <Grid item md>
                      <TypographyText variant="subtitle1">
                        {charge.payment_term}
                      </TypographyText>
                    </Grid>
                    <Grid item md>
                      <TypographyText
                        variant="h6"
                        style={
                          charge.status === "unpaid"
                            ? { color: "red" }
                            : { color: "blue" }
                        }>
                        {charge.status}
                      </TypographyText>
                    </Grid>
                  </Grid>
                </span>
              </span>
            );
          })}
        </Grid>
      );
    } else {
      return (
        <Grid>
          <TypographyText variant="h5">No charges created</TypographyText>
        </Grid>
      );
    }
  };

  const renderTenant = (tenant) => {
    if (tenant) {
      return <TenantByLid tenant={tenant} classes={classes} newClasses={newClasses} />;
    } else
      return <AddTenant apartmentId={`${id}`} rentDate={apartment.rent_payment_date} />;

    // ;
  };

  return (
    <Fragment>
      {/* ---------------------------------------------------------------------------------------- */}
      {/* HEADER */}
      {/* ---------------------------------------------------------------------------------------- */}
      <div className={newClasses.newApartmentSummaryHeader}>
        <h1>General Description</h1>
        <div className="summaryButtons">
          {apartment.status === "vacant" &&
            apartment.advert_status === false &&
            checkingApartmentId !== null &&
            checkingApartmentId?.apartment_id === `${propertyId}-${id}` &&
            checkingApartmentId?.advert_status === false && (
              <CustomButton
                className={classes.btnEditApartment}
                type="button"
                buttonText="Advertise For Listing"
                style={{
                  backgroundColor: "#4786FF",
                  color: "#E8F0F9",
                  textTransform: "none",
                  border: "1px solid #4786FF",
                  padding: "8px 27px",
                  fontSize: "12px",
                  fontFamily: "Open Sans, sans-serif"
                }}
                isLoading={advertising}
                onClick={() => handleAdvertiseApartment(`${propertyId}-${id}`)}
              />
            )}

          <CustomButton
            className={classes.btnEditApartment}
            type="button"
            buttonText="Edit apartment"
            style={{
              backgroundColor: "#4786FF",
              color: "#E8F0F9",
              textTransform: "none",
              color: "#F4F5F7",
              border: "1px solid #4786FF",
              padding: "8px 27px",
              fontSize: "12px",
              fontFamily: "Open Sans, sans-serif"
            }}
            onClick={handleStartEditingApartment}
          />
        </div>
      </div>

      {/* ---------------------------------------------------------------------------------------- */}
      {/* UNDERLINE */}
      {/* ---------------------------------------------------------------------------------------- */}
      <Hidden smDown>
        <span className={classes.underLine}></span>
        <hr style={{ position: "relative", bottom: "0.5rem" }}></hr>
      </Hidden>

      {/* ---------------------------------------------------------------------------------------- */}
      {/* MAJOR APARTMENT INFO */}
      {/* ---------------------------------------------------------------------------------------- */}
      <Grid className={classes.xsContainer}>
        <Grid container className="sub-1">
          <Grid container spacing={2} className="sub-2">
            <Grid item md={3} sm={3}>
              <TypographyText variant="subtitle1">Status of apartment</TypographyText>
              <TypographyText variant="h5">{apartment.status}</TypographyText>
            </Grid>
            <Grid item md={3} sm={3}>
              <TypographyText variant="subtitle1">Payment Frequency</TypographyText>
              <TypographyText variant="h5">
                {capitalizeFirstLetter(apartment.payment_term)}
              </TypographyText>
            </Grid>
            <Grid item md={3} sm={3}>
              <TypographyText variant="subtitle1">Bedrooms</TypographyText>
              <TypographyText variant="h5">{apartment.no_of_bedrooms} </TypographyText>
            </Grid>
            <Grid item md={3} sm={3}>
              <TypographyText variant="subtitle1">Bathrooms</TypographyText>
              <TypographyText variant="h5">{apartment.no_of_toilet} </TypographyText>
            </Grid>
            <Grid item md={3} sm={3}>
              <TypographyText variant="subtitle1">Sitting Room</TypographyText>
              <TypographyText variant="h5">
                {apartment.no_of_sitting_room}{" "}
              </TypographyText>
            </Grid>
            <Grid item md={3} sm={3}>
              <TypographyText variant="subtitle1">
                Rent Price ({apartment.payment_term})
              </TypographyText>
              <TypographyText variant="h5">{toCurrency(apartment.price)}</TypographyText>
            </Grid>
            <Grid item md={3} sm={3}>
              <TypographyText variant="subtitle1">Position of Apartment</TypographyText>
              <TypographyText variant="h5">{apartment.apartment_position}</TypographyText>
            </Grid>
            <Grid item md={3} sm={3}>
              <TypographyText variant="subtitle1">Apartment Floor</TypographyText>
              <TypographyText variant="h5">{apartment.floor}</TypographyText>
            </Grid>
            <Grid item md={3} sm={3}>
              <TypographyText variant="subtitle1">Entrance</TypographyText>
              <TypographyText variant="h5">{apartment.entrance}</TypographyText>
            </Grid>
          </Grid>

          {/* ---------------------------------------------------------------------------------------- */}
          {/* DESCRIPTION */}
          {/* ---------------------------------------------------------------------------------------- */}
          <Grid container className="description-wrapper">
            <TypographyText
              variant="h5"
              style={{ color: "#043B5C", marginBottom: "10px" }}>
              Description
            </TypographyText>
            <TypographyText variant="subtitle2">
              {apartment.extra_description}
            </TypographyText>
          </Grid>

          {/* ---------------------------------------------------------------------------------------- */}
          {/* CHARGES */}
          {/* ---------------------------------------------------------------------------------------- */}
          <Grid container className={classes.charges}>
            <Grid container className="tw-justify-between tw-items-center">
              <TypographyText variant="h5" style={{ color: "#043B5C" }}>
                Apartment Charges
              </TypographyText>
              <CustomButton
                type="button"
                buttonText="Add Bills"
                style={{
                  backgroundColor: "#4786FF",
                  color: "#E8F0F9",
                  textTransform: "none",
                  color: "#F4F5F7",
                  border: "1px solid #4786FF",
                  padding: "8px 27px",
                  fontSize: "12px",
                  fontFamily: "Open Sans, sans-serif"
                }}
                onClick={handleAddBills}
              />
            </Grid>
            {charges && !loadingCharges ? (
              checkCharges()
            ) : (
              <CircularProgress size={20} className="icon" color="inherit" />
            )}
          </Grid>

          {/* ---------------------------------------------------------------------------------------- */}
          {/* AMENITIES */}
          {/* ---------------------------------------------------------------------------------------- */}
          <Grid container style={{ display: "block" }}>
            <TypographyText
              variant="h5"
              style={{ color: "#043B5C", marginBottom: "10px" }}>
              Amenities
            </TypographyText>
            <Grid item md={12} style={{ display: "flex", flexWrap: "wrap" }}>
              {apartment.extras ? (
                apartment.extras?.map((extra, i) => {
                  return (
                    <span
                      variant="outlined"
                      className={classes.amenities}
                      key={i}
                      style={{ whiteSpace: "nowrap" }}>
                      {extra}
                    </span>
                  );
                })
              ) : (
                <h2 style={{ fontSize: "15px" }}>No Amenities</h2>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid className={newClasses.newLeaseAndTenantSection}>
        {/* ---------------------------------------------------------------------------------------- */}
        {/* LEASE HEADER */}
        {/* ---------------------------------------------------------------------------------------- */}
        <Grid
          container
          justify="space-between"
          style={{ alignItems: "flex-end", marginBottom: "1rem" }}
          className={classes.lease}>
          <Grid item md={8} sm={8}>
            <TypographyText variant="body2" className={classes.curLease}>
              Payment History
            </TypographyText>
          </Grid>
          <Hidden xsDown>
            <Grid
              item
              md
              sm
              xs={12}
              style={{ display: "flex", justifyContent: "flex-end" }}></Grid>
          </Hidden>
        </Grid>
        <Hidden xsDown>
          <hr
            style={{ position: "relative", bottom: "0.5rem", marginBottom: "1rem" }}></hr>
        </Hidden>

        {/* ---------------------------------------------------------------------------------------- */}
        {/* LEASE DETAILS */}
        {/* ---------------------------------------------------------------------------------------- */}
        {apartment.status === "occupied" ? (
          <Fragment>
            <Grid container className={classes.leaseBody}>
              <Grid
                className={classes.xsContainer}
                style={{ borderRadius: "0px 0px 10px 10px" }}>
                <Grid container className="date">
                  <Grid item md={3} className={classes.dateDiv}>
                    <TypographyText variant="h1">Start</TypographyText>
                    <TypographyText variant="h2">
                      {getStart().toDateString()}
                    </TypographyText>
                  </Grid>
                  <Grid item md={3} className={classes.dateDiv}>
                    <TypographyText variant="h1">End</TypographyText>
                    <TypographyText variant="h2">
                      {getEnd().toDateString()}
                    </TypographyText>
                  </Grid>
                  <Grid item md={3} className={classes.dateDiv}>
                    <TypographyText variant="h1">Monthly Rent</TypographyText>
                    <TypographyText variant="h2">
                      {toCurrency(getMonthlyRent())}
                    </TypographyText>
                  </Grid>
                </Grid>
                <Grid container style={{ display: "block" }}>
                  <TypographyText variant="h6" className="days">
                    Days Remaining
                  </TypographyText>
                  <TypographyText variant="h2" style={{ marginBottom: "20px" }}>
                    {remDays()}
                  </TypographyText>
                  <span>
                    <BorderLinearProgress variant="determinate" value={val()} />
                  </span>
                  <Grid item md={6} className="renew">
                    {" "}
                    <TypographyText variant="caption">Renew</TypographyText>
                    <TypographyText variant="caption">Terminate</TypographyText>
                  </Grid>
                </Grid>
              </Grid>
              <Hidden smUp>
                <div></div>
                {/* Not yet used */}
                {/* <Button
                                    className={classes.editBtn}
                                    style={{
                                        fontSize: "0.6rem",
                                        padding: "0.8rem 0"
                                    }}>
                                    Edit Terms
                                </Button> */}
              </Hidden>
            </Grid>

            {/* ---------------------------------------------------------------------------------------- */}
            {/* TENANT DETAILS */}
            {/* ---------------------------------------------------------------------------------------- */}
            <>
              <ApartmentTenant
                tenantLid={apartmentTenants[0]?.user_id}
                apartmentId={`${id}`}
              />
            </>
          </Fragment>
        ) : (
          <Fragment>
            <div className={classes.sad}>
              <Grid className="sad-under">
                <img src={sad} alt="sad" />
                <br></br>
                <TypographyText variant="body1" style={{ marginTop: "3%" }}>
                  No lease yet, to add tenant edit apartment.
                </TypographyText>
                <br></br>
                <Hidden xsDown>
                  <Button
                    className={classes.btnEditApartment}
                    style={{ padding: "4px 17%" }}
                    onClick={handleStartEditingApartment}>
                    Edit Apartment
                  </Button>
                </Hidden>
              </Grid>
            </div>
            <Hidden smUp>
              <Button
                className={classes.editBtn}
                style={{
                  margin: "2% 0 10% 0 ",
                  fontSize: "0.6rem",
                  padding: "0.8rem 0"
                }}>
                Add Tenent
              </Button>
            </Hidden>
          </Fragment>
        )}
      </Grid>
    </Fragment>
  );
};

export default ApartmentInfo;
