import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Route, Redirect, useRouteMatch } from "react-router-dom";
import { usePropertyStyles } from "./createPropAndApart.styles";
import Stepper from "./subComponents/customStepper/stepper.component";
import PropertyForm from "#/components/propertyForm/propertyForm";
import ApartmentForm from "#/components/apartmentForm/apartmentForm";
import BackComponent from "#/components/propertycomponents/back-component";
import usePropertyForm from "#/hooks/propertyservice-hooks/usePropertyForm";
import useApartmentForm from "#/hooks/propertyservice-hooks/useApartmentForm";
import { clearCreatedTenant } from "#/store/actions/tenant.actions";
import BillSettings from "../propertyAndApartmentDetails/components/BillSettings";

const Property = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { allPropertyInfo } = useSelector((state) => state.property);

  // Step navigation
  let currentStep = 0;

  // Helper hooks
  const { path, url } = useRouteMatch();
  const matchesFirstRoute = useRouteMatch(`${url}/property`);
  const matchesSecondRoute = useRouteMatch(`${url}/apartment`);
  const matchesThirdRoute = useRouteMatch(
    `${url}/bill-settings/:apartmentId/:propertyId`
  );
  const classes = usePropertyStyles();

  const { propertyFormHelpers } = usePropertyForm(false, history);
  const { apartmentFormHelpers } = useApartmentForm(allPropertyInfo);

  const {
    propertyId,
    propertyCoverImage,
    propertyCoverImagePreview,
    handleSetCoverImage,
    propertyName,
    handleSetPropertyName,
    addressDropdownHelpers,
    addMultipleImagesHelpers,
    errors,
    handleSubmitProperty,
    creatingProperty,
    propertyTypeHelpers,
    createPropertyAs
  } = propertyFormHelpers;

  const {
    apartmentId,
    apartmentStatusHelpers,
    apartmentPaymentFreqHelpers,
    apartmentFormFields,
    apartmentPositionHelpers,
    handleOnChangeApartmentInput,
    addMultipleApartmentImagesHelpers,
    creatingApartment,
    advertStatus,
    handleSetAdvertStatus,
    handleCreateApartment,
    apartDetailsDropdownHelpers,
    apartmentErrors,
    apartmentFloorHelpers
  } = apartmentFormHelpers;

  // Clear tenant data on mount
  useEffect(() => {
    dispatch(clearCreatedTenant());
  }, [dispatch]);

  // Stepper control logic
  if (matchesFirstRoute) {
    currentStep = 0;
  } else if (matchesSecondRoute) {
    currentStep = 1;
  } else if (matchesThirdRoute) {
    currentStep = 2;
  }

  // // Redirect logic for accessing apartment form without property
  // if (matchesSecondRoute && !allPropertyInfo) {
  //   return <Redirect to={`${path}/property`} />;
  // }

  return (
    <>
      <div style={{ marginBottom: "20px", display: "inline-block" }}>
        <BackComponent
          url={url}
          apartmentId={apartmentId}
          propertyId={propertyFormHelpers}
        />
      </div>
      <div className={classes.wrapper}>
        {/* Stepper */}
        <div className={classes.stepperWrapper}>
          <Stepper currentStep={currentStep} />
        </div>
        <form>
          <div>
            {/* Property Form */}
            <Route path={`${path}/property`} exact>
              <PropertyForm
                propertyId={propertyId}
                handleSetCoverImage={handleSetCoverImage}
                propertyCoverImagePreview={propertyCoverImagePreview}
                propertyCoverImage={propertyCoverImage}
                handleSetPropertyName={handleSetPropertyName}
                propertyName={propertyName}
                addressDropdownHelpers={addressDropdownHelpers}
                handleSubmitProperty={handleSubmitProperty}
                creatingProperty={creatingProperty}
                multipleImagesHelpers={addMultipleImagesHelpers}
                errors={errors}
                propertyTypeHelpers={propertyTypeHelpers}
                createPropertyAs={createPropertyAs}
              />
            </Route>

            {/* Apartment Form */}
            <Route path={`${path}/apartment`} exact>
              <ApartmentForm
                allPropertyInfo={allPropertyInfo}
                handleSetCoverImage={handleSetCoverImage}
                propertyCoverImagePreview={propertyCoverImagePreview}
                apartmentId={apartmentId}
                apartmentStatusHelpers={apartmentStatusHelpers}
                apartmentPaymentFreqHelpers={apartmentPaymentFreqHelpers}
                apartmentFormFields={apartmentFormFields}
                apartmentPositionHelpers={apartmentPositionHelpers}
                handleOnChangeApartmentInput={handleOnChangeApartmentInput}
                addMultipleApartmentImagesHelpers={addMultipleApartmentImagesHelpers}
                creatingApartment={creatingApartment}
                advertStatus={advertStatus}
                handleSetAdvertStatus={handleSetAdvertStatus}
                handleCreateApartment={handleCreateApartment}
                apartDetailsDropdownHelpers={apartDetailsDropdownHelpers}
                apartmentErrors={apartmentErrors}
                apartmentFloorHelpers={apartmentFloorHelpers}
              />
            </Route>

            {/* Bill Settings */}
            <Route path={`${path}/bill-settings/:apartmentId/:propertyId`} exact>
              <BillSettings allPropertyInfo={allPropertyInfo} />
            </Route>

            {/* Default Redirect */}
            <Route path={path} exact>
              <Redirect to={`${path}/property`} />
            </Route>
          </div>
        </form>
      </div>
    </>
  );
};

export default Property;
