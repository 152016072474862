import { propertyServiceApi } from "#/apis/propertyServiceApi";
import { useState, useCallback } from "react";

const usePost = (url, method, options = {}) => {
  const { onSuccess, onError } = options;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const mutate = useCallback(
    async (postData) => {
      setLoading(true);
      setError(null);
      setData(null);

      try {
        const axiosInstance = propertyServiceApi();
        const response = await axiosInstance[method](url, postData);
        const result = response.data.content || response.content || response.data;
        setData(result);
        if (onSuccess) onSuccess(result);
      } catch (err) {
        setError(err.message || "An error occurred.");
        if (onError) onError(err);
      } finally {
        setLoading(false);
      }
    },
    [url, method, onSuccess, onError]
  );

  return { data, loading, error, mutate };
};

export default usePost;
