import {
  LOGIN,
  LOGOUT,
  SIGNUP,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_SUCCESS,
  // UPDATE_USER_PROFILE_FAIL,
  UPDATE_USER_INFO,
  SAVE_TOKEN,
  LOAD_POPUP,
  VALIDATE_USER_ID_SUCCESS,
  VALIDATE_USER_ID_FAIL,
  VALIDATE_USER_ID,
  SENDING_EMAIL_SUCCESS,
  SENDING_EMAIL_PENDING,
  SENDING_EMAIL_FAIL,
  SEND_FORGET_PASSWORD_PENDING,
  SEND_FORGET_PASSWORD_FAIL,
  SEND_FORGET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_PENDING,
  RESET_PASSWORD_SUCCESS,
  UPDATE_USER_OTHER_INFORMATION_FAIL,
  UPDATE_USER_OTHER_INFORMATION_PENDING,
  UPDATE_USER_OTHER_INFORMATION_SUCCESS,
  UPDATE_PROFILE_INFORMATION_PENDING,
  UPDATE_PROFILE_INFORMATION_SUCCESS,
  UPDATE_PROFILE_INFORMATION_FAIL,
  GET_PROFILE_INFORMATION_PENDING,
  GET_PROFILE_INFORMATION_SUCCESS,
  GET_PROFILE_INFORMATION_FAIL,
  UPDATE_BUSINESS_INFORMATION_PENDING,
  UPDATE_BUSINESS_INFORMATION_SUCCESS,
  UPDATE_BUSINESS_INFORMATION_FAIL,
  GET_BUSINESS_INFORMATION_PENDING,
  GET_BUSINESS_INFORMATION_SUCCESS,
  GET_BUSINESS_INFORMATION_FAIL,
  GET_USER_OTHER_INFORMATION_FAIL,
  GET_USER_OTHER_INFORMATION_PENDING,
  GET_USER_OTHER_INFORMATION_SUCCESS,
  SET_VALIDATION_ERRORS
} from "../constants";

import axios from "axios";
import { saveCookie, clearCookieValue } from "../../components/utils/cookie";
import {
  showNotifications,
  alertError,
  alertClear,
  alertSuccess
} from "./notification.actions";
import { Banks, getIzyPayBalance } from "./izypay.actions";
import { getTenantsUnderPropertyManager } from "./tenant.actions";

const config = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "X-Requested-With": "XMLHttpRequest"
  }
};

export const sendLoginRequest = (data, modal, history, cb) => async (dispatch) => {
  try {
    // make api call
    const res = await axios
      .post("/user/auth/login", data, config)
      .then((res) => res.data);
    if (res.status) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.token}`;

      localStorage.setItem("data", JSON.stringify(res.data)); // Add user data to local storage
      localStorage.setItem("token", res.data.token);

      const userProfile = await axios.get("/user/profile", config);

      dispatch({
        type: LOGIN,
        token: res.data.token,
        user: userProfile.data.data
      });

      history.push(
        modal ? "/dashboard" : localStorage.getItem("LAST_PAGE_VIEWED") || "/dashboard"
      );

      if (modal) {
        dispatch({
          type: LOAD_POPUP,
          popup: true
        });
      }

      if (data.user.is_email_verified) {
        dispatch(getIzyPayBalance());
        dispatch(Banks());
        if (data.user.roles.includes("Agent" || "Landlord")) {
          dispatch(getTenantsUnderPropertyManager(res.data.user.id));
        }
      }
    }
    cb();
  } catch (error) {
    dispatch(alertError(error.response?.data.message));
    setTimeout(() => {
      dispatch(alertClear());
    }, 3000);
  } finally {
    cb();
  }
};

export const sendSignupRequest = (data, history, cb) => async (dispatch) => {
  try {
    const signUp = await axios.post("/user/auth/register", data, config);
    axios.defaults.headers.common["Authorization"] = `Bearer ${signUp.data.data.token}`;

    dispatch(alertSuccess(signUp.data.message));

    saveCookie("liveizyToken", signUp.data.token);

    const userProfile = await axios.get("/user/profile", config);
    dispatch({
      type: SIGNUP,
      token: signUp.data.token,
      user: userProfile.data.data
    });
    localStorage.setItem("token", signUp.data.data.token);

    //! TODO: REMOVE WHEN YOU FIX FETCHING USER DATA FROM LOCALSTORE
    const user = {
      token: signUp.data.data.token,
      user: userProfile.data.data
    };
    localStorage.setItem("data", JSON.stringify(user));

    history.push("/dashboard");
    dispatch({
      type: LOAD_POPUP,
      popup: true
    });
  } catch (error) {
    if (error.response.status === 422) {
      const errorData = error.response?.data?.data; // Array of errors object
      let validationErrors = Object.assign({}, ...errorData);

      dispatch({
        type: SET_VALIDATION_ERRORS,
        validationErrors
      });
    } else {
      dispatch(alertError(error?.response?.data?.message));

      // //to clear error message from the backend
      setTimeout(() => {
        dispatch(alertClear());
      }, 3000);
    }
  } finally {
    cb();
  }
};

export const logout = (navbar) => async (dispatch) => {
  try {
    // send api request first
    if (navbar) {
      const res = await axios.delete("/user/auth/logout", config).then((res) => res.data);
    }

    // clear local storage and cookie
    let keysToRemove = [
      "data",
      "token",
      "activeplan",
      "bodyState",
      "active",
      "PAY_RENT_AND_CHARGES",
      "RENEW_RENT",
      "PAY_CHARGES",
      "ESM_PAY_FEES",
      "ESTATE_MEMBER",
      "ESTATE_ROLE",
      "PERMISSION_TOKEN",
      "ESM_PAY_FEES",
      "RENTAL_PAYMENTS"
    ];

    const lastViewed = window.location.pathname;

    // clear token value
    clearCookieValue("liveizyToken");
    keysToRemove.forEach((k) => localStorage.removeItem(k));

    localStorage.setItem("LAST_PAGE_VIEWED", lastViewed);
    dispatch({
      type: LOGOUT
    });
    window.location.replace("/");
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(showNotifications(error.response.data.message, "error"));
    } else {
      dispatch(showNotifications("An Error Occurred. Please try again", "error"));
    }
  }
};

export const updateUserProfile = (data, cb, cb2) => async (dispatch) => {
  // config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
  dispatch({ type: UPDATE_USER_PROFILE });
  try {
    const res = await axios.put("/user/profile", data, config).then((res) => res.data);
    if (res.status) {
      dispatch({
        type: UPDATE_USER_PROFILE_SUCCESS,
        payload: res.data
      });
      // localStorage.setItem("user", JSON.stringify(res.data.user));
      let auth = JSON.parse(localStorage.getItem("data"));
      const data = { ...auth };
      data.user = res.data;
      localStorage.setItem("data", JSON.stringify(data));
      dispatch(showNotifications("Profile Updated Succesfully", "success"));
      cb2();
    }
  } catch (error) {
    if (error.response.status === 500)
      return dispatch(showNotifications("Server Error. Please try again", "error"));
    if (error.response && error.response.data !== undefined) {
      if (error.response.data) {
        const errors = error.response.data.data;
        errors.forEach((error) => {
          Object.entries(error).forEach(([key, value]) => {
            dispatch(alertError(value));
          });
        });
      } else {
        dispatch(alertError(error.response.data.message));
      }
      setTimeout(() => {
        dispatch(alertClear());
      }, 3000);
    } else return;
  } finally {
    cb();
  }
};

export const saveToken = (token, user) => async (dispatch) => {
  try {
    dispatch({ type: SAVE_TOKEN, token: token, user: user });
    const userProfile = await axios.get("/user/profile", config);
    dispatch({ type: SAVE_TOKEN, token: token, user: userProfile.data.data });
  } catch (error) {
    dispatch(logout());
  }
};

export const updateUser = (user) => (dispatch) => {
  dispatch({ type: UPDATE_USER_INFO, user: user });
};

export const validateID = (userID, cb) => async (dispatch) => {
  dispatch({ type: VALIDATE_USER_ID, loading: true });
  try {
    await axios.get(`/user/validate/liveizy/${userID}`, config).then((res) => {
      if (res.data.status) {
        dispatch({
          type: VALIDATE_USER_ID_SUCCESS,
          liveIzyUser: res.data.data,
          loading: false
        });
      }
      cb();
      dispatch(alertClear());
    });
  } catch (error) {
    dispatch({
      type: VALIDATE_USER_ID_FAIL,
      loading: false
      // err: 'Invalid User ID',
    });
    if (error.response.data) {
      if (error.response.status === 404)
        return dispatch(alertError("Liveizy ID not found"));
      return dispatch(showNotifications(error.response.data.message, "error"));
    }
    dispatch(alertError("invalid Liveizy ID"));
  }
};
export const sendEmail = (cb) => async (dispatch) => {
  dispatch({ type: SENDING_EMAIL_PENDING });
  try {
    const response = await axios.post("/user/auth/email/resend-verification", config);
    if (response.data.status) {
      const { message } = response.data;
      dispatch({ type: SENDING_EMAIL_SUCCESS, message });
      cb();
    }
  } catch (error) {
    const err = error.response?.data?.message;
    dispatch({ type: SENDING_EMAIL_FAIL, err });
  }
};

export const forgetPassword = (data, history, cb) => async (dispatch) => {
  dispatch({ type: SEND_FORGET_PASSWORD_PENDING });
  const configs = {
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
      "X-Requested-With": "XMLHttpRequest"
    }
  };
  try {
    const response = await axios.post("/user/auth/password/forgot", data, configs);
    if (response.data.status) {
      const { message } = response.data;
      dispatch({ type: SEND_FORGET_PASSWORD_SUCCESS, msg: message });
      dispatch(showNotifications(message, "success"));
    }
    cb();
    history.push("/");
  } catch (error) {
    dispatch({ type: SEND_FORGET_PASSWORD_FAIL, error });
    if (error.response?.data?.code === 400) {
      dispatch(alertError(error.response.data?.message));
    }
    dispatch(alertError(error.response?.data?.message));

    //to clear error message from the backend
    setTimeout(() => {
      dispatch(alertClear());
    }, 3000);
  }
};
export const resetPassword = (data, history, cb) => async (dispatch) => {
  dispatch({ type: RESET_PASSWORD_PENDING });

  const configs = {
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
      "X-Requested-With": "XMLHttpRequest"
    }
  };
  try {
    const response = await axios.post("/user/auth/password/reset", data, configs);
    if (response.data.status) {
      const { message } = response.data;
      dispatch({ type: RESET_PASSWORD_SUCCESS });
      dispatch(showNotifications(message, "success"));
    }
    cb();
    history.push("/");
  } catch (error) {
    dispatch({ type: RESET_PASSWORD_FAIL, error });
    if (error.response?.data?.errors) {
      dispatch(alertError(error.response?.data?.errors?.password[0]));
    } else if (error.response?.data?.message) {
      dispatch(alertError(error.response?.data?.message));
    }
    cb();
    //to clear error message from the backend
    setTimeout(() => {
      dispatch(alertClear());
    }, 3000);
  }
};

export const updateOtherInformation = (data, cb) => async (dispatch) => {
  dispatch({ type: UPDATE_USER_OTHER_INFORMATION_PENDING });

  try {
    const response = await axios.put("user/other-information", data, config);

    if (response.data.status) {
      dispatch({
        type: UPDATE_USER_OTHER_INFORMATION_SUCCESS,
        payload: response.data.data
      });

      dispatch(showNotifications("Other Informations Updated Succesfully", "success"));
      cb();
    }
  } catch (error) {
    dispatch({ type: UPDATE_USER_OTHER_INFORMATION_FAIL, error });
    dispatch(showNotifications("An error occured while updating.", "error"));
  }
};

export const getOtherInformation = (data, cb) => async (dispatch) => {
  dispatch({ type: GET_USER_OTHER_INFORMATION_PENDING });
  try {
    const response = await axios.get("user/other-information", config);
    if (response.data.status) {
      dispatch({
        type: GET_USER_OTHER_INFORMATION_SUCCESS,
        payload: response.data.data
      });
      cb();
    }
  } catch (error) {
    dispatch({ type: GET_USER_OTHER_INFORMATION_FAIL, error });
  }
};

export const updateProfileInformation = (data, cb) => async (dispatch) => {
  dispatch({ type: UPDATE_PROFILE_INFORMATION_PENDING });

  try {
    const response = await axios.put("/user/profile", data, config);

    if (response.data.status) {
      dispatch({
        type: UPDATE_PROFILE_INFORMATION_SUCCESS,
        payload: response.data.data
      });
      dispatch(showNotifications(response.data.message, "success"));
      dispatch({ type: UPDATE_USER_INFO, user: response.data.data });
      //UPDATING USER DETAILS IN LOCAL STORAGE
      let auth = JSON.parse(localStorage.getItem("data"));
      const auths = { ...auth };
      auths.user = response.data.data;
      localStorage.setItem("data", JSON.stringify(auths));
      cb();
    }
  } catch (error) {
    dispatch({ type: UPDATE_PROFILE_INFORMATION_FAIL, error });
  }
};
export const getProfileInformation = () => async (dispatch) => {
  dispatch({ type: GET_PROFILE_INFORMATION_PENDING });

  try {
    const response = await axios.get("/user/profile", config);
    if (response.data.status) {
      dispatch({
        type: GET_PROFILE_INFORMATION_SUCCESS,
        payload: response.data.data
      });
    }
  } catch (error) {
    dispatch({ type: GET_PROFILE_INFORMATION_FAIL, error });
  }
};

export const updateBusinessInformation = (data, cb) => async (dispatch) => {
  dispatch({ type: UPDATE_BUSINESS_INFORMATION_PENDING });

  try {
    const response = await axios.put("/user/business-information", data, config);

    if (response.data.status) {
      dispatch({
        type: UPDATE_BUSINESS_INFORMATION_SUCCESS,
        payload: response.data.data
      });
      dispatch(showNotifications(response.data.message, "success"));
      cb();
    }
  } catch (error) {
    dispatch({ type: UPDATE_BUSINESS_INFORMATION_FAIL, error });
  }
};

export const getBusinessInformation = () => async (dispatch) => {
  dispatch({ type: GET_BUSINESS_INFORMATION_PENDING });

  try {
    const response = await axios.get("/user/business-information", config);

    if (response.data.status) {
      dispatch({
        type: GET_BUSINESS_INFORMATION_SUCCESS,
        payload: response.data.data
      });
    }
  } catch (error) {
    dispatch({ type: GET_BUSINESS_INFORMATION_FAIL, error });
  }
};

/*
    The response from this request does not need to be attached to the global store, 
    cause it changes every single time depending on the logged in users need. Therefore adding 
    it to the global state would only serve to unnecessarily populate the store.

    If you think otherwise please kindly let me know. Thank you
*/
export const getUserInfoFromLiveizyId = async (id) => {
  try {
    const res = await axios.get(`/user/validate/liveizy/${id}`, config);

    if (res.data.status) {
      return res.data.data.user;
    }
  } catch (error) {
    return error;
  }
};
