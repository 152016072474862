import { propertyServiceApi } from "#/apis/propertyServiceApi";
import axios from "axios";
import { useState, useEffect, useCallback } from "react";

const useFetch = (url, options = {}) => {
  const { loadingMessage, errorMessage } = options;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = useCallback(
    async (signal) => {
      if (!url) return;
      setLoading(true);
      setError(null);
      setData(null);

      let isMounted = true;

      try {
        const response = await propertyServiceApi().get(url, { signal });
        const result = response.data.content || response.content || response.data;
        if (isMounted && result) {
          setData(result);
        }
      } catch (err) {
        if (isMounted && !axios.isCancel(err)) {
          setError(err || errorMessage || "An error occurred.");
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }

      return () => {
        isMounted = false;
      };
    },
    [url, errorMessage]
  );

  useEffect(() => {
    const controller = new AbortController();
    fetchData(controller.signal);

    return () => {
      controller.abort();
    };
  }, [url, fetchData]);

  const refetch = () => {
    fetchData();
  };

  return { data, loading, error, refetch, loadingMessage, errorMessage };
};

export default useFetch;
