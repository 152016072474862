import React, { useState } from "react";
import PaperContainer from "#/dashboard/components/paperContainer/Index";
import { styled } from "@mui/material";
import { SubHeader, SubtitleSubtext } from "#/shared/components";
import { toCurrency } from "#/components/utils/util";
import { Button } from "@mui/material";
import { TbEdit } from "react-icons/tb";
import { MdOutlineDelete } from "react-icons/md";
import { formatPrice, renderDate2 } from "#/shared/utils/general";
import { CircularProgress, Modal } from "@material-ui/core";
import AddBillSet from "./AddBillSet";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import useFetch from "#/hooks/util-hooks/useFetch";
import usePost from "#/hooks/util-hooks/usePost";
import { alertError, alertSuccess } from "#/store/actions/notification.actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { populateCurrentPropertyInfo } from "#/store/actions/property.actions";

const BillSettings = ({ allPropertyInfo }) => {
  const [addBill, setAddBill] = useState(false);
  const [editBill, setEditBill] = useState(false);
  const [selectedBill, setSelectedBill] = useState(null);

  const { apartmentId, propertyId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const { data: detailsData, loading: detailsIsLoading } = useFetch(
    apartmentId ? `/apartment/show/${apartmentId}?type=apartment` : null
  );

  const details = detailsData?.payload;

  const { data, loading, refetch } = useFetch(
    apartmentId ? `/bill/get_apartment_bills/${apartmentId}` : null
  );

  console.log(allPropertyInfo);

  const handleCreatePropertyClick = () => {
    dispatch(
      populateCurrentPropertyInfo(allPropertyInfo, () => {
        history.push({ pathname: "/create/apartment" });
      })
    );
  };

  if (detailsIsLoading && loading)
    return (
      <div className="tw-flex mtop-8 tw-justify-center tw-items-center">
        <CircularProgress />
      </div>
    );

  return (
    <>
      <Modal
        open={addBill}
        onClose={() => setAddBill(false)}
        className="tw-flex tw-justify-center tw-items-center tw-w-full">
        <AddBillSet apartmentID={apartmentId} setModal={setAddBill} refetch={refetch} />
      </Modal>
      <Modal
        open={editBill}
        onClose={() => setEditBill(false)}
        className="tw-flex tw-justify-center tw-items-center tw-w-full">
        <AddBillSet
          bill={selectedBill}
          apartmentID={apartmentId}
          setModal={setEditBill}
          refetch={refetch}
        />
      </Modal>

      <div className="mtop-8 mtop-0">
        <SubHeader title={"Apartment Details"} />
        <Card>
          <SubtitleSubtext
            xs={5}
            sm={5}
            md={2.5}
            subtitle={"LAID"}
            subtext={details?.apartment_id}
          />
          <SubtitleSubtext
            xs={7}
            sm={7}
            md={4.5}
            subtitle={"Address"}
            subtext={
              details?.house_number ? (
                <>
                  {details?.house_number} {details?.street} {details?.state}{" "}
                  {details?.lga} {details?.lcda}
                </>
              ) : null
            }
          />
          <SubtitleSubtext
            xs={5}
            sm={5}
            md={2.5}
            subtitle={"Rent Tenure"}
            subtext={
              details?.payment_term ? (
                <span style={{ textTransform: "capitalize" }}>
                  {details?.payment_term}
                </span>
              ) : null
            }
          />
          <SubtitleSubtext
            xs={7}
            sm={7}
            md={2.5}
            subtitle={"Rent Value"}
            subtext={`${toCurrency(details?.price)}`}
          />
        </Card>
      </div>

      <div className="mtop-5">
        <SubHeader
          title={"Apartment Bills"}
          component={<AddBill onClick={() => setAddBill(true)} />}
        />

        <div className="bill-grid">
          {!data?.data && data?.data < 0 ? (
            <div>No Charges Here yet</div>
          ) : (
            data?.data.map((el) => (
              <BillCard
                key={el?._id}
                data={el}
                name={el?.name}
                amount={el?.amount}
                date={el?.payment_date}
                terms={el?.payment_term}
                status={el?.status}
                setting={el?.amount_settings}
                setEditBill={setEditBill}
                setSelectedBill={setSelectedBill}
                refetch={refetch}
                dispatch={dispatch}
              />
            ))
          )}
        </div>
      </div>

      <div className="tw-w-full bill-actions tw-flex tw-justify-center tw-items-center tw-gap-32 mtop-7">
        <Button
          sx={{ fontSize: "15px", padding: "0.7rem 3rem" }}
          variant="contained"
          color="primary"
          onClick={() => handleCreatePropertyClick()}>
          Create new apartment
        </Button>
        <Button
          sx={{ fontSize: "15px", padding: "0.7rem 3rem" }}
          variant="contained"
          color="primary"
          onClick={() => {
            history.push(
              `/property-list/property-apartment/${propertyId}?apartmentId=${apartmentId}`
            );
          }}>
          Finish
        </Button>
      </div>
    </>
  );
};

export default BillSettings;

const Card = styled(PaperContainer)`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  padding: 2rem;
  margin: 0.5rem 0;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const AddBill = ({ onClick }) => (
  <Button
    sx={{ fontSize: "0.75rem", padding: "0.5rem 1.3rem", border: "2px solid" }}
    variant="outlined"
    color="primary"
    onClick={onClick}>
    Add New Bill
  </Button>
);

const BillCard = ({
  name,
  amount,
  date,
  setting,
  terms,
  status,
  setEditBill,
  setSelectedBill,
  data,
  refetch,
  dispatch
}) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false); // State to show/hide delete confirmation

  const { mutate } = usePost(`/bill/delete_bill/${data?._id}?type=bill`, "delete", {
    onSuccess: (data) => {
      dispatch(alertSuccess(data.message || data.msg));
      refetch();
    },
    onError: (error) => {
      dispatch(alertError(error.message || error.msg));
      refetch();
    }
  });

  const handleDelete = () => {
    mutate();
    setShowDeleteConfirmation(false);
  };

  return (
    <div className="billbox">
      <div className="tw-flex tw-justify-between">
        <h1 className="tw-text-[1.2rem] tw-font-semibold">{name}</h1>
        <div className="tw-flex tw-items-center tw-gap-3">
          <TbEdit
            size={23}
            className="tw-cursor-pointer"
            onClick={() => {
              setEditBill(true);
              setSelectedBill(data);
            }}
          />
          <MdOutlineDelete
            size={23}
            className="tw-cursor-pointer"
            onClick={() => setShowDeleteConfirmation(true)}
          />
        </div>
      </div>

      <div className="tw-flex tw-flex-col tw-gap-2 mtop-1">
        <h3 className="tw-font-semibold">
          Amount: <span className="tw-font-medium"> {formatPrice(amount)}</span>
        </h3>
        <h3 className="tw-font-semibold">
          Payment Date: <span className="tw-font-medium">{renderDate2(date)}</span>{" "}
        </h3>
        <h3 className="tw-font-semibold">
          Bill Setting: <span className="tw-capitalize">{setting}</span>
        </h3>
        <h3 className="tw-font-semibold tw-capitalize">
          Payment Terms: <span className="tw-font-medium">{terms}</span>
        </h3>
        <h3 className="tw-font-semibold">
          Status:
          <span
            className={`tw-font-semibold tw-capitalize ${
              status === "paid" ? "tw-text-green-500" : "tw-text-red-500"
            }`}>
            {status}
          </span>
        </h3>
      </div>

      {/* Delete confirmation buttons */}
      {showDeleteConfirmation && (
        <div className="mtop-3 tw-flex tw-items-center tw-justify-between tw-gap-4">
          <p className="tw-text-red-500">Do you want to proceed to delete this bill?</p>
          <button
            style={{ padding: "5px 20px" }}
            onClick={handleDelete}
            className="tw-bg-red-500 tw-text-white tw-rounded tw-cursor-pointer">
            Yes
          </button>
          <button
            style={{ padding: "5px 20px" }}
            onClick={() => setShowDeleteConfirmation(false)}
            className="tw-bg-gray-300 tw-text-black tw-rounded tw-cursor-pointer">
            No
          </button>
        </div>
      )}
    </div>
  );
};
