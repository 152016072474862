import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, CircularProgress } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import TablePagination from "@material-ui/core/TablePagination";
import { getAllProperties } from "#/store/actions/property.actions";
import { propertyListingStyles } from "./styles";
import PageHeader from "#/components/propertycomponents/page-header";
import { showNotifications } from "#/store/actions/notification.actions";
import PlansInfoModal from "./components/PlansInfoModal";
import SinglePropertyRibbon from "./components/propertyRibbon/SinglePropertyRibbon";
import SadFaceIconComponent from "#/components/ui-components/sadFaceIcon.component";
import AddButton from "#/components/propertycomponents/button-component";

const PropertiesListing = (props) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [toggleModal, setToggleModal] = React.useState(false);
  const [currentModal, setCurrentModal] = React.useState("bothPlans");

  const { allProperties, loading } = useSelector((state) => state.property);
  const user = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();

  const { history } = props;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleModal = (trueOrFalse, context = "bothPlans") => {
    setCurrentModal(context);
    setToggleModal(trueOrFalse);
  };

  useEffect(() => {
    dispatch(getAllProperties());
  }, [dispatch]);

  const RouteToProperty = () => {
    const { is_email_verified } = user;
    if (!is_email_verified) {
      dispatch(showNotifications("Please, verify your email to continue", "error"));
      return;
    } else {
      history.push("/create");
    }
  };

  const classes = propertyListingStyles();

  return (
    <div
      className={"property-listing-page" + " " + classes.root}
      id="property-listing-page">
      {toggleModal && <PlansInfoModal {...{ currentModal, handleModal }} />}
      {/**HEADER */}
      <PageHeader>
        <h3>Properties</h3>
      </PageHeader>

      {/**SEARCH BOXES */}
      <div className={"plp-filter-block" + " " + classes.search_container}>
        <div className="plp-lipman-ad">
          <p>
            You can now guarantee your rent with our{" "}
            <Link
              title="learn more"
              className="plp-la-link"
              onClick={() => handleModal(true, "lipman")}
              to="#">
              LiPMaN
            </Link>{" "}
            and{" "}
            <Link
              title="learn more"
              className="plp-la-link"
              onClick={() => handleModal(true, "lirem")}
              to="#">
              LiReM
            </Link>{" "}
            plans.
          </p>
          <Button
            onClick={() => handleModal(true, "bothPlans")}
            className="plp-la-btn"
            variant="contained"
            color="primary">
            Create a Plan
          </Button>
        </div>
        <div className={classes.right_section}>
          <Button className={classes.btn} onClick={RouteToProperty}>
            <AddIcon className="icon" />
            <span className="btn_name">New Property</span>
          </Button>
        </div>
      </div>

      {/**TABLE */}
      <section style={{ marginTop: "40px" }}>
        {!loading && !allProperties.length && (
          <SadFaceIconComponent>
            {" "}
            <p>You have not added any property</p>
            <AddButton />
          </SadFaceIconComponent>
        )}
        {loading && (
          <div
            style={{
              width: "100%",
              height: 200,
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}>
            <CircularProgress />
          </div>
        )}
        {allProperties
          .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
          .map((property, idx) => {
            return <SinglePropertyRibbon key={idx} property={property} />;
          })}
        {allProperties.length > rowsPerPage && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
              component="div"
              count={allProperties.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              className="paginate"
            />
          </div>
        )}
      </section>
    </div>
  );
};

export default PropertiesListing;
